.get-in-touch-button {
  background-color: #67347e;
  border: none;
  padding: 5px 30px 5px 30px;
  margin-left: auto;
  color: white;
  font-size: 1.8em;
  width: 8em;
  outline: none;
  cursor: pointer;
}

.get-in-touch-button:hover {
  transition: all 0.2s ease;
  transform: scale(1.1) perspective(1px);
  box-shadow: 3px 5px 5px #888888;
}

.link {
  text-decoration: none;
  font-size: 1.5em;
  font-weight: 600;
  background: -webkit-linear-gradient(315deg, #e40d7e 10%, #67347e 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid #e0e1ec;
  height: 38px;
  border-radius: 19px;
  padding: 0 19px 0 19px;
  vertical-align: text-bottom;
}
