.vr-brands-section {
  margin: 100px 0 100px 0;
  text-align: center;

  h1 {
    font-size: 2em;
    font-weight: 600;
    background: -webkit-linear-gradient(315deg, #e40d7e 45%, #67347e 55%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    font-size: 2em;
    font-weight: 200;
    background: -webkit-linear-gradient(315deg, #e40d7e 45%, #67347e 55%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .cards-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }

  .card {
    background-color: #67347e;
    width: 400px;
    padding: 50px;

    img {
      height: 100px;
      width: auto;
      margin-bottom: 30px;
    }

    p {
      color: white;
      font-size: 1.7em;
    }
  }

  @media only screen and (max-width: 850px) {
    .card {
      width: 50%;
    }

    img {
      height: 70px;
    }
  }

  .card:hover {
    transition: all 0.2s ease;
    transform: scale(1.1) perspective(1px);
    box-shadow: 3px 5px 5px #888888;
  }
}

@media only screen and (max-width: 415px) {
  .vr-brands-section {
    .cards-container {
      flex-direction: column;

      .card {
        width: 100%;
        padding: 0;
        margin-bottom: 5px;
      }

      img {
        height: 50px;
        margin: 20px;
      }

      p {
        margin: 0 30px 30px 30px;
      }
    }
  }
}
