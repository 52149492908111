@import "./styles/Header.scss";
@import "./styles/Description.scss";
@import "./styles/Common.scss";
@import "./styles/Carousel.scss";
@import "./styles/Product.scss";
@import "./styles/VRBrands.scss";
@import "./styles/Footer.scss";
@import "./styles/Imprint.scss";

html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}
body {
  margin: auto;
  padding: 0;
  max-width: 1500px;
  overflow-x: hidden;
  width: 100vw;
}

body,
button {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
}

a {
  text-decoration: none;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.5;
    transform-origin: 50% 50%;
    transform: rotate(-2deg) scale(5);
    transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    opacity: 1;
    transform: rotate(15deg) scale(1);
  }
}
