.footer-section {
  border-top: solid 1px #67347e;
  padding: 50px 100px 50px 100px;
  display: flex;
  justify-content: space-between;

  .vres-logo {
    height: 100px;
    cursor: pointer;
    margin-bottom: 30px;
  }

  .logo-and-links {
    display: flex;
    flex-direction: column;
  }

  .footer-links {
    color: #67347e;
    font-size: 1.5em;
  }

  .subcribe-container {
    h3 {
      font-size: 1.3em;
      font-weight: 200;
      color: #767cad;
    }

    .subscribe-input {
      position: relative;

      input {
        background-color: #f6f7f9;

        border: none;
        height: 40px;
        width: 350px;
        border-radius: 20px;
        padding-left: 20px;
        line-height: 1.3em;
      }

      input::placeholder {
        color: #cdd0e1;
        font-size: 1.3em;
        font-weight: 200;
        outline: none;
      }

      button {
        position: absolute;
        height: 38px;
        border-radius: 19px;
        border: 1px solid #e0e1ec;
        outline: none;
        right: 0;
        top: 1px;
        padding: 0 19px 0 19px;
        background-color: white;
        cursor: pointer;
      }

      .subscribe-text {
        font-weight: 600;
        background: -webkit-linear-gradient(315deg, #e40d7e 40%, #67347e 60%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .email-error {
        color: #e40d7e;
        margin: 5px;
        font-size: 1.2em;
        position: absolute;
      }

      .email-success {
        color: #67347e;
        margin: 5px;
        font-size: 1.2em;
        position: absolute;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .footer-section {
    padding: 50px;
  }
}

@media only screen and (max-width: 415px) {
  .footer-section {
    display: flex;
    flex-direction: column;

    .subcribe-container {
      .subscribe-input {
        input {
          width: 100%;
        }

        button {
          right: -20px;
          top: 2px;
        }
      }
    }
  }
}
