.vres-header {
  z-index: 10;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: rgba(256, 256, 256, 0.9);
  display: flex;
  border-bottom: solid 1px #67347e;
  height: 70px;
  padding: 20px 100px 20px 100px;
  align-items: center;

  .inflight-logo,
  .frontrow-logo {
    height: 50px;
    padding-right: 50px;
  }
}

@media only screen and (max-width: 850px) {
  .vres-header {
    padding: 10px;
    margin: auto;

    .inflight-logo,
    .frontrow-logo {
      height: 40px;
      padding-right: 50px;
    }
  }
}

@media only screen and (max-width: 415px) {
  .vres-header {
    width: 100vw;
    justify-content: space-between;
    padding: 0;

    .get-in-touch-button {
      font-size: 1.3em;
      margin-left: 0;
    }

    .inflight-logo,
    .frontrow-logo {
      height: 30px;
      padding: 0;
    }
  }
}
