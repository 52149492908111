.product-section {
  position: relative;
  margin: 150px;
  text-align: center;

  .headset-and-bubble {
    position: relative;
  }

  .no-own-headset-comic-bubble {
    transform: rotate(15deg) scale(1);
    position: absolute;
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    height: 120px;
    right: -100px;
  }

  h1 {
    font-size: 2em;
    font-weight: 600;
    background: -webkit-linear-gradient(315deg, #e40d7e 45%, #67347e 55%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .columns {
    display: grid;
    align-items: center;
    max-width: 1400px;
    margin: auto;
  }

  img.headset {
    width: 400px;
    margin: auto;
  }

  @media only screen and (min-width: 415px) and (max-width: 1100px) {
    .columns {
      grid-template-columns: 50% 50%;
      grid-template-rows: 25% 25% 25% 25%;
    }

    img.headset {
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }

  @media only screen and (min-width: 1100px) {
    .columns {
      grid-template-columns: 30% 40% 30%;
      grid-template-rows: 33% 33% 33%;
    }

    .card-container[data-orientation="right"] {
      text-align: right;
    }

    .card-container[data-orientation="left"] {
      text-align: left;
    }

    .headset-and-bubble {
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 4;
    }

    img.headset {
      width: 400px;
      margin: auto;
    }

    .no-own-headset-comic-bubble {
      right: 80px;
    }
  }

  @media only screen and (max-width: 850px) {
    img.headset {
      width: 345px;
    }
  }

  .card-container {
    width: 100%;

    h2 {
      line-height: 1em;
      font-size: 2em;
    }

    p {
      font-size: 1.5em;
      width: 100%;
    }
  }

  .card.img-product-platform {
    height: 65px;
  }
  .card.img-product-content {
    height: 80px;
    padding-right: 10px;
  }
  .card.img-product-management {
    height: 80px;
    padding-right: 10px;
  }
  .card.img-product-hardware {
    height: 80px;
  }
  .card.img-product-deployment {
    height: 80px;
    padding-left: 10px;
  }
  .card.img-product-analytics {
    height: 80px;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .product-section {
    margin: 100px;
  }
}

@media only screen and (max-width: 415px) {
  .product-section {
    margin: 50px 15px 0 15px;
    position: relative;

    .columns {
      display: grid;
      align-items: center;
      width: 100%;
      margin: auto;
      grid-template-columns: 100%;
    }

    img.headset {
      order: -1;
    }

    .no-own-headset-comic-bubble {
      left: 200px;
    }
  }
}
