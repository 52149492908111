.description-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 70px);
  min-height: 700px;

  .columns {
    margin: 50px 100px 0 100px;
    display: flex;

    .left-column {
      z-index: 1;
      width: 650px;
      p {
        a {
          text-decoration: underline;
          color: #67347e;
        }
      }
    }

    .right-column {
      position: absolute;
      overflow: visible;
    }
  }

  .inflight-collage {
    z-index: 0;
    height: 500px;
    width: 640px;
    background: url("../assets/inflight_collage.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .e2e-comic-bubble {
    position: absolute;
    top: 40px;
    right: 100px;
    z-index: 1;
    transform: rotate(15deg) scale(1);
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    height: 120px;
    left: 700px;
  }

  @media only screen and (min-width: 850px) and (max-width: 1500px) {
    .right-column {
      left: 750px;
    }
  }

  @media only screen and (min-width: 1500px) {
    .right-column {
      right: 0;
    }
  }

  @media only screen and (max-width: 1100px) {
    .columns {
      flex-direction: column;
    }

    .right-column {
      top: 400px;
      left: 600px;

      .inflight-collage {
        height: 450px;
        width: 500px;
      }
    }
  }

  .vres-logo {
    height: 100px;
  }

  .greyish-banner {
    background-color: black;
    opacity: 0.3;
    color: white;
    padding: 10px 0 10px 0;
    height: 150px;

    li:before {
      content: "\2713";
      padding-right: 10px;
    }
    ul {
      height: 100%;
      list-style-type: none;
      display: grid;
      align-items: center;
      justify-items: self-start;
      grid-template-columns: 50% 50%;
      padding-inline-start: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    @media only screen and (max-width: 1100px) {
      ul {
        grid-template-columns: 100%;
      }
    }

    li {
      font-size: 1.8em;
      margin-left: 100px;
      width: 100%;
    }
  }

  h1 {
    font-size: 2em;
    font-weight: 600;
    background: -webkit-linear-gradient(315deg, #e40d7e, #67347e 40%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    color: #67347e;
    font-size: 1.75em;
  }
}

@media only screen and (max-width: 850px) {
  .description-section {
    height: 100%;

    .columns {
      margin: 10px;

      .left-column {
        z-index: 1;
        width: 100%;

        .vres-logo {
          height: 80px;
        }
      }

      .right-column {
        position: relative;
        margin-left: auto;
        top: 0;
        left: 10px;

        .inflight-collage {
          z-index: 0;
          height: 450px;
          width: 500px;
        }
      }
    }

    .greyish-banner {
      li {
        margin: 0 0 0 5px;
      }

      @media only screen and (max-width: 360px) {
        li {
          font-size: 1.3em;
        }
      }
    }

    .e2e-comic-bubble {
      left: 240px;
      top: 0;
    }

    .right-column {
      top: 480px;
      right: 0px;
      left: 350px;
    }
  }
}
