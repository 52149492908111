.carousel-section {
  margin-top: 200px;
  padding: 50px 0 50px 0;
  margin-bottom: 200px;
  color: grey;
  text-align: center;
  background-color: rgb(233, 229, 229);
  font-size: 1.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .carousel-header {
    padding-bottom: 20px;
  }

  .carousel-children {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 30px 0 50px 0;
    width: 100%;
  }

  .p-carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
  }

  .brand {
    height: auto;
    width: 100%;
  }
}
