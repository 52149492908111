.imprint-section {
  padding: 0 100px 50px 100px;
  color: #2a317d;

  h1 {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }

  p {
    font-size: 1.4em;
  }

  h1.colored {
    background: -webkit-linear-gradient(315deg, #e40d7e 10%, #67347e 20%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media only screen and (max-width: 415px) {
  .imprint-section {
    padding: 0 50px 50px 50px;
  }
}
